import React, {useEffect, useState} from 'react';
import Logo from "../assets/Lubtrac.jpg"
import Avatar from "@material-ui/icons/AccountCircle";


export default function Navbar(){

  const [username, setUsername] = useState("");

  useEffect(() => {
    setUsername(localStorage.getItem('username'))
  }, [localStorage.getItem('username')])


  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  }

    return (   
      <nav class="navbar navbar-expand-lg navbar-dark" style={{backgroundColor: '#1e88e5'}}>
      <a class="navbar-brand" href="#">
      <img src={Logo} width="120" height="45" class="d-inline-block align-top" alt="" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" href="#">
              <Avatar />
              {username} <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="nav-item active">
             <a class="nav-link" onClick={handleLogout}>
                <u>Cerrar sesión</u>
             </a>
          </li>
        </ul>
      </div>
    </nav>
    );
}