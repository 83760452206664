import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Filtro from "./Components/Filtro";
//import axios from "../../axios";
import axios from "../../axios";
import MecanicaCard from "./Components/MecanicaCard";
import MecanicaEmpty from "./Components/MecanicaEmpty";
import * as ReactBootStrap from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    marginTop: "2%",
    alignItems: "center",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto",
  },
  titulo: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#000000",
  },
}));

export default function MainMecanica({ fetchUrl }) {
  
  const d = new Date();
  var n = d.getMonth()+1;
  var y = d.getFullYear();
  
  const classes = useStyles();
  const [mecanicas, setMecanicas] = React.useState([]);
  
  const [loading, setLoading] = React.useState(false);
  const [menu, setMenu] = React.useState(1);
  const [mes, setMes] = React.useState(n);
  const [year, setYear] = React.useState(y);


  useEffect(() => {
    async function fetchData() {
      const data = {
        menu: menu,
        mes: mes,
        year: year,
      };
      if(menu === 0) {
        const request = await axios
        .get('/mecanica', {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
          },
        })
        .then((response) => {
          setMecanicas(response.data);
          setLoading(true);
        })
        .catch((e) => {
          if (e.response?.status == 401) {
            localStorage.clear();
            window.location.reload();
          }
        });
      }else{
        const request = await axios
        .post(fetchUrl, data, {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
          },
        })
        .then((response) => {
          setMecanicas(response.data);
          setLoading(true);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.clear();
            window.location.reload();
          }
        });
      }
    }
    fetchData();
  }, [menu, mes, year]);

  const handleMenu = (event) => {
    setMenu(event.target.value);
  };

  const handleMes = (event) => {
    switch (event.target.value) {
      case "Enero":
        setMes(1);
        break;
      case "Febrero":
        setMes(2);
        break;
      case "Marzo":
        setMes(3);
        break;
      case "Abril":
        setMes(4);
        break;
      case "Mayo":
        setMes(5);
        break;
      case "Junio":
        setMes(6);
        break;
      case "Julio":
        setMes(7);
        break;
      case "Agosto":
        setMes(8);
        break;
      case "Septiembre":
        setMes(9);
        break;
      case "Octubre":
        setMes(10);
        break;
      case "Noviembre":
        setMes(11);
        break;
      case "Diciembre":
        setMes(12);
        break;
      default:
        setMes(1);
        break;
    }
  };

  const handleYear = (event) => {
    setYear(event.target.value);
  };


  return (
    <div className={classes.body}>
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Filtro
              handleMenu={handleMenu}
              handleMes={handleMes}
              handleYear={handleYear}
            />
          </Grid>
          {loading ? (
            mecanicas.length > 0 ? (
              mecanicas.map((mecanica, index) => (
                <Grid item xs={6}>
                  <MecanicaCard key={index} mecanica={mecanica} />
                </Grid>
              ))
            ) : (
              <div style={{ marginLeft: "30%" }}>
                <MecanicaEmpty />
              </div>
            )
          ) : (
            <div className={classes.loading}>
              <ReactBootStrap.Spinner animation="border" variant="primary" />
            </div>
          )}
        </Grid>
      </div>
    </div>
  );
}
