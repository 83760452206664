import "./App.css";
import React, { useState } from "react";
import { BrowserRouter, Route, useHistory, Switch } from "react-router-dom";
import Login from "./Pages/Login";
import Promociones from "./Pages/Promociones";
import Giveaways from "./Pages/Giveaways";
import Banners from "./Pages/Banners";
import Kilometros from "./Pages/Kilometros";
import HomeMecanica from "./Pages/Promociones/HomeMecanica";
import CrearPromocion from "./Pages/Promociones/CrearPromocion";
import MainMecanica from "./Pages/Promociones/MainMecanica";
import CrearMecanica from "./Pages/Promociones/CrearMecanica";
import UploadBanner from "./Pages/Banners/UploadBanner.jsx";
import MainGiveaway from "./Pages/Giveaways/MainGiveaway";
import UploadKilometros from "./Pages/Kilometros/UploadKilometros";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Layout from "./Components/Layout";
import useToken from "./useToken";
import requests from "./requests";

function App() {
  const { token, setToken } = useToken();
  console.log(token);

  if (!token) {
    return <Login setToken={setToken} />;
  }

  return (
    <BrowserRouter>
    <Switch>
      <Route exact path="/Login">
        <Layout>
          <Login />
        </Layout>
      </Route>
      <Route exact path="/">
        <Layout>
          <Promociones />
        </Layout>
      </Route>
      <Route exact path="/Mecanicas/:id">
        <Layout>
          <HomeMecanica
            fetchPromociones={requests.fetchPromociones}
            fetchCodigosParticipantes={requests.fetchCodigosParticipantes}
          />
        </Layout>
      </Route>
      <Route exact path="/Mecanicas/:id/CrearPromocion">
        <Layout>
          <CrearPromocion fetchPromociones={requests.fetchPromociones}/>
        </Layout>
      </Route>
      <Route exact path="/CrearMecanica">
        <Layout>
          <CrearMecanica />
        </Layout>
      </Route>
      <Route exact path="/Giveaways">
        <Layout>
          <Giveaways />
        </Layout>
      </Route>
      <Route exact path="/Banners">
        <Layout>
          <Banners />
        </Layout>
      </Route>
      <Route exact path="/Kilometros">
        <Layout>
          <Kilometros />
        </Layout>
      </Route>
      <Route exact path="/UploadBanner">
        <Layout>
          <UploadBanner />
        </Layout>
      </Route>
      <Route exact path="/MainGiveaway">
        <Layout>
          <MainGiveaway fetchUrl={requests.fetchGiveaways} />
        </Layout>
      </Route>
      <Route exact path="/UploadKilometros">
        <Layout>
          <UploadKilometros />
        </Layout>
      </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
