import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

export default function Vigencia(props) {
  const [inicio, setInicio] = React.useState(new Date("01/01/2021"));
  const [errors, setErrors] = React.useState("");
  const [fin, setFin] = React.useState(new Date("02/01/2021"));

  const handleChange = (event) => {
    switch (event.target.name) {
      case "inicio":
        setInicio(event.target.value);
        {props.setState('inicio', event.target.value)};
        break;
      case "fin":
        if (event.target.value < inicio) {
          setFin(event.target.value);
          setErrors({
            fin: "El valor del final no puede ser menor al de inicio",
          });
        } else {
          setErrors("");
          setFin(event.target.value);
          {props.setState('fin', event.target.value)};
        }
        break;
      default:
        setErrors("");
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Typography style={{ textAlign: "end" }}>Vigencia inicio:</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="date"
          type="date"
          name="inicio"
          value={props.getState("inicio")}
          onChange={handleChange}
          style={{ width: "210px" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{ textAlign: "end" }}>Vigencia Fin:</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="date"
          type="date"
          name="fin"
          value={props.getState("fin")}
          onChange={handleChange}
          error={Boolean(errors?.fin)}
          helperText={errors.fin}
          style={{ width: "210px" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          Por cada {props.getState("litros")} litros + ${" "}
          {props.getState("precio")} pesos, recibe {props.getState("cantidad")}{" "}
          piezas de {props.getState("giveaway")} {props.getState("descripcion")}.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          Límite: {props.getState("limite")} piezas por cliente
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: "center" }}>
          Vigente del {props.getState("inicio")} al {props.getState("fin")};
        </Typography>
      </Grid>
    </Grid>
  );
}
