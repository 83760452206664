import React from "react";
import { Steps, Step } from "react-step-builder";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Sidebar from "../Components/Sidebar";
import Valores from "./Giveaways/Valores";
import Vigencia from "./Giveaways/Vigencia";
import Sucursales from "./Giveaways/Sucursales";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import * as ReactBootStrap from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    alignItems: "center",
    marginTop: "10%",
  },
}));



export default function Giveaways() {
  const [alertFail, setAlertFail] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const Before = (props) => {
    return (
      <span>
        This component will be rendered before the Step components in every step
      </span>
    );
  };
  
  const After = (props) => {
    return (
      <span>
        This component will be rendered after the Step components in every step
      </span>
    );
  };

  const Navigation = (props) => {
    function Atras() {
      if (props.progress === 0) {
        return false;
      }
      return true;
    }
  
    function Despues() {
      if (props.progress === 1) {
        return false;
      }
      return true;
    }

  
    const handleAdelante = () => {
      setAlertFail(false);
      if (props.current == 1) {
        if (props.getState("giveaway") && props.getState("giveaway") != "" && props.getState("litros") && props.getState("litros") != "" && props.getState("precio") && props.getState("precio") != "" && props.getState("cantidad") && props.getState("cantidad") != "" && props.getState("limite")  && props.getState("limite") != "") {
          var data = {
            codigo_sap : props.getState("giveaway")
          }
          Axios.post(
            process.env.REACT_APP_API.concat("producto/search")
            ,data
          ,{headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
            .then((response) => {
              if (response.data == false) {
                setAlertMessage('Codigo SKU inexistente');
                setAlertFail(true);      
              }else{
                if(props.getState("litros") > 0 && props.getState("precio") > 0 && props.getState("cantidad") > 0 && props.getState("limite") > 0 ){
                  {props.setState('descripcion', response.data)}
                  props.next(true);
                }else{
                setAlertMessage('Ninguno de los valores puede ser 0.');
                setAlertFail(true);  
                }
              }         
            })
            .catch((e) => {
              if(e.response.status == 401){
                localStorage.clear();
                window.location.reload();
              }
            });
        } else {
          setAlertMessage('Favor de llenar todos los campos');
          setAlertFail(true);
        }
      } else if(props.current == 2){
          if(props.getState("inicio") && props.getState("fin")){
            if(props.getState("fin") > props.getState("inicio")){
              props.next();
            }else{
              setAlertMessage('La fecha de fin no puede ser menor a la de inicio');
              setAlertFail(true);    
            }
          }else{
            setAlertMessage('La fecha de fin no puede ser menor a la de inicio');
            setAlertFail(true);   
          }
      }
    };
  
    return (
      <div style={{ marginTop: "3%" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={props.prev}
          disabled={!Atras()}
        >
          Anterior
        </Button>
        <a> </a>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdelante}
          disabled={!Despues()}
          type="submit"
        >
          Siguiente
        </Button>
      </div>
    );
  };

  const classes = useStyles();
  const config = {
    before: Before,
    after: After,
    navigation: {
      component: Navigation, // a React component with special props provided automatically
      location: "after", // or before
    },
  };


  return (
    <div className={classes.body}>
      <Sidebar select="Giveaways" />
      <div className={classes.container}>
        {loading ? (
          <div className={classes.loading}>
            <ReactBootStrap.Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Steps config={config}>
            <Step component={Valores} />
            <Step component={Vigencia} />
            <Step component={Sucursales} />
          </Steps>
        )}
        {alertFail && (
          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Alert variant="filled" severity="error">
              {alertMessage}
            </Alert>
          </Grid>
        )}
      </div>
    </div>
  );
}
