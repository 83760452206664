import React, { useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "./Components/Card";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Filtro from "./Components/Filtro";
import { Typography } from "@material-ui/core";
import axios from "../../axios";
import * as ReactBootStrap from "react-bootstrap";
import MecanicaEmpty from "../Promociones/Components/MecanicaEmpty";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    alignItems: "center",
    marginTop: "1%",
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  table: {
    minWidth: 300,
  },
}));

export default function MainGiveaway({ fetchUrl }) {

  const d = new Date();
  var n = d.getMonth()+1;
  var y = d.getFullYear();

  const [giveaways, setGiveaways] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [mes, setMes] = React.useState(n);
  const [year, setYear] = React.useState(y);
  const [showDialog, setShowDialog] = React.useState(false);
  const [codigos, setCodigos] = React.useState([]);

  useEffect(() => {
    async function fetchData(){
      const data = {
        "mes": mes,
        "year": year
      };

      const request = await axios.post(fetchUrl, data, {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((response) => {
        setGiveaways(response.data);
        setLoading(true)
      }).catch((e) => {
        if(e.response?.status == 401){
          localStorage.clear();
          window.location.reload();
        }
      });
      ;
    }
    fetchData();
  }, [mes, year]);

  const handleMes = (event) =>{
    switch (event.target.value) {
      case "Enero":
        setMes(1);
        break;
      case "Febrero":
        setMes(2);
        break;
      case "Marzo":
        setMes(3);
        break;
      case "Abril":
        setMes(4);
        break;
      case "Mayo":
        setMes(5);
        break;
      case "Junio":
        setMes(6);
        break;
      case "Julio":
        setMes(7);
        break;
      case "Agosto":
        setMes(8);
        break;
      case "Septiembre":
        setMes(9);
        break;
      case "Octubre":
        setMes(10);
        break;
      case "Noviembre":
        setMes(11);
        break;
      case "Diciembre":
        setMes(12);
        break;
      default:
        setMes(1);
        break;
    }
  }

  const handleYear = (event) => {
    setYear(event.target.value);
  }

  const showModal = (codigos) => {
    setShowDialog(true);
    setCodigos(codigos);
  }

  const classes = useStyles();
  return (
    <div className={classes.body}>
      <Sidebar select="Giveaways" />
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Filtro handleMes={handleMes} handleYear={handleYear}/>
          </Grid>
          {loading ?  (giveaways.length > 0 ? ( giveaways.map((giveaway, index) => (
              <Grid item xs={6}>
                <Card key={index} giveaway={giveaway} showModal={showModal}/>
              </Grid>
              )))
            : (<div style={{ marginLeft: "30%" }}>
                <MecanicaEmpty />
              </div>)
            ) : (
              <div className={classes.loading}><ReactBootStrap.Spinner animation="border" variant="primary" /></div>
            )}
        </Grid>
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          component={Link}
          to="/Giveaways"
        >
          <AddIcon />
        </Fab>
        <div>
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Codigos participantes"}
          </DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                    <TableRow>
                      <TableCell align="center">Codigo SAP</TableCell>
                      <TableCell align="center">Descripción</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                 {Object.keys(codigos)?.map((keyName, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">
                            {codigos[keyName]?.SKU}
                          </TableCell>
                          <TableCell align="center">
                            {codigos[keyName]?.descripcion}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)} color="primary" autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
        </div>
      </div>
    </div>
  );
}
