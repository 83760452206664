import React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../../Components/Sidebar";
import ImageIcon from "@material-ui/icons/Image";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import PublishIcon from "@material-ui/icons/Publish";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { useHistory } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import Axios from "axios";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    marginTop: "2%",
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto"
  },
  item: {
    marginTop: "15%",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  formControl: {
    width: "500px",
  },
  alert:{
    marginTop: "100px",
  }
}));

export default function UploadBanner() {
  const classes = useStyles();
  const [files, setFiles] = React.useState();
  const [inicio, setInicio] = React.useState();
  const [fin, setFin] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [alertFail, setAlertFail] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [name, setName] = React.useState();
  const [errors, setErrors] = React.useState("");
  const [sucursales, setSucursales] = React.useState([]);
  let history = useHistory();
  const sucs = ["TRA1", "TRA2", "TRA3", "TRA5", "TRA6", "TRA7", "TRA8", "TRA9"];
  const CHARACTER_LIMIT = 35;

  const handleChange = (event) => {
    switch (event.target.name) {
      case "inicio":
        setInicio(event.target.value);
        break;
      case "fin":
        if (event.target.value < inicio) {
          setFin(event.target.value);
          setErrors({
            fin: "El valor del final no puede ser menor al de inicio",
          });
        } else {
          setErrors("");
          setFin(event.target.value);
        }
        break;
      case "sucursales":
        setSucursales(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const uploadBanner = () => {
    if(inicio && fin && files.length > 0 && sucursales.length > 0){
      if(fin > inicio){
        const formData = new FormData();

    formData.append("image", files[0]);
    formData.append("titulo", name);
    formData.append("fecha_inicio", inicio);
    formData.append("fecha_fin", fin);

    formData.append("sucursales", [sucursales]);
    
    for (var i = 0; i < sucursales.length; i++) {
      formData.append("sucursales[]", sucursales[i]);
    }
    setLoading(true);

    Axios.post(process.env.REACT_APP_API.concat("banner"), formData,{headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((res) => {
        if(res.status == 200){
          setAlertSuccess(true);
          history.push("/Banners");
        }else{
          setAlertMessage(res.data.message);
          setAlertFail(true);
        }
      })
      .catch((err) => {
        setAlertFail(true);
      });
      }else{
      setAlertMessage('La fecha de fin no puede ser menor a la de inicio');
      setAlertFail(true);
      }
    }else{
      setAlertMessage('Favor de llenar todos los campos');
      setAlertFail(true);
    }
    
  };

  return (
    <div className={classes.body}>
      <Sidebar select="Banners" />
      <div className={classes.container}>
        {loading ? (
          <div className={classes.loading}>
          <ReactBootStrap.Spinner animation="border" variant="primary" />
          </div>
          ):(
          <Grid container spacing={5}>
          <Grid item xs={8}>
            <DropzoneArea
              acceptedFiles={["image/*"]}
              Icon={ImageIcon}
              dropzoneText={"Arrastra el banner hasta aquí, o haz clic para abrir el explorador de archivos."}
              onChange={(files) => setFiles(files)}
              onAlert={(message, variant) =>
                console.log(`${variant}: ${message}`)
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Grid item xs={8}>
              <Typography>Título del banner:</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Título"
                inputProps={{
                maxlength: CHARACTER_LIMIT
                }}
                value={name}
                onChange={handleName}
                variant="outlined"
                style={{ width: "max-content" }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.item}>Vigencia inicio:</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="date"
                type="date"
                name="inicio"
                value={inicio}
                onChange={handleChange}
                //defaultValue="2017-05-24"
                style={{ width: "210px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.item}>Vigencia Fin:</Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="date"
                type="date"
                name="fin"
                value={fin}
                onChange={handleChange}
                //defaultValue="2017-05-24"
                error={Boolean(errors?.fin)}
                helperText={errors.fin}
                style={{ width: "210px" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-mutiple-chip-label">Sucursales</InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                name="sucursales"
                multiple
                value={sucursales}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
              >
                {sucs.map((suc) => (
                  <MenuItem key={suc} value={suc}>
                    {suc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        )}
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          component={Link}
          onClick={uploadBanner}
        >
          <PublishIcon />
        </Fab>
        <div className={classes.alert}>
        {alertSuccess && (
          <Alert variant="filled" severity="success">
            Banner subido exitosamente!
          </Alert>
        )}
        {alertFail && (
          <Alert variant="filled" severity="error">
           {alertMessage} 
          </Alert>
        )}
        </div>
      </div>
    </div>
  );
}
