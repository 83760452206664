import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "./Components/Card";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Filtro from "./Components/Filtro";
import { Typography } from "@material-ui/core";
import axios from "../../axios";
import * as ReactBootStrap from "react-bootstrap";
import MecanicaEmpty from "../Promociones/Components/MecanicaEmpty";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto",
  },
}));

export default function MainBanner({ fetchUrl }) {

  const d = new Date();
  var n = d.getMonth()+1;
  var y = d.getFullYear();
  

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [banners, setBanners] = React.useState([]);
  const [mes, setMes] = React.useState(n);
  const [year, setYear] = React.useState(y);

  useEffect(() => {
    async function fetchData() {
      const data = {
        "mes": mes,
        "year": year
      };
      const request = await axios.post(fetchUrl, data, {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((response) => {
        setBanners(response.data);
        setLoading(true);
      }).catch((e) => {
        if(e.response.status == 401){
          localStorage.clear();
          window.location.reload();
        }
      });
    }
    fetchData();
  }, [mes, year, fetchUrl]);

  const handleMes = (event) =>{
    switch (event.target.value) {
      case "Enero":
        setMes(1);
        break;
      case "Febrero":
        setMes(2);
        break;
      case "Marzo":
        setMes(3);
        break;
      case "Abril":
        setMes(4);
        break;
      case "Mayo":
        setMes(5);
        break;
      case "Junio":
        setMes(6);
        break;
      case "Julio":
        setMes(7);
        break;
      case "Agosto":
        setMes(8);
        break;
      case "Septiembre":
        setMes(9);
        break;
      case "Octubre":
        setMes(10);
        break;
      case "Noviembre":
        setMes(11);
        break;
      case "Diciembre":
        setMes(12);
        break;
      default:
        setMes(1);
        break;
    }
  }

  const handleYear = (event) => {
    setYear(event.target.value);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={10}>
        <Filtro handleMes={handleMes} handleYear={handleYear}/>
      </Grid>
      {loading ? (
        banners.length > 0 ? (
          banners.map((banner, index) => (
          <Grid item xs={6}>
            <Card key={index} banner={banner}/>
          </Grid>
        ))
        ):(<div style={{ marginLeft: "30%" }}>
                <MecanicaEmpty />
            </div>)
      ) : (
        <Grid item xs={12}>
          <div className={classes.loading}><ReactBootStrap.Spinner animation="border" variant="primary" /></div>
        </Grid>
      )}

      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={Link}
        to="/UploadBanner"
      >
        <AddIcon />
      </Fab>
    </Grid>
  );
}
