import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Alert from "@material-ui/lab/Alert";
import imag from "../assets/login.jpg";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage:
    `url(${imag})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    height: "650px",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(15, 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "25px",
    border: "2px solid #5d99c6",
    padding: "20px",
    width: "500px",
    height: "400px",
    backgroundColor: "white",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#2AE414",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  derecha: {
    backgroundColor: "#8aacc8",
  },
}));


export default function Login({ setToken }) {
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = React.useState();
  const [password, setPassword] = React.useState();
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [alertFail, setAlertFail] = React.useState(false);

  async function loginUser() {
    let data = {
      username: username,
      password: password
    };
    axios
    .post(process.env.REACT_APP_API.concat("/user/login"), 
    data, { headers: {"Content-Type": "application/json"}}
    )
    .then((res) => {
        setAlertSuccess(true);
        setToken(res.data.access_token);
        localStorage.setItem('username', username);
        history.push('/');
    })
    .catch((err) => {
      setAlertFail(true);
    });
  };

  const handleChange = (event) => {
    event.target.name == "email"
      ? setUsername(event.target.value)
      : setPassword(event.target.value);
  };

    const handleButton = async e => {
      e.preventDefault();
      await loginUser();
    };
  
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={3} md={6} className={classes.image}> 
      
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        component={Paper}
        elevation={3}
        square
        className={classes.derecha}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <AttachMoneyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Gestor de Promociones
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Cuenta"
              name="email"
              onChange={handleChange}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              onChange={handleChange}
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleButton}
            >
              Ingresar
            </Button>
            <Box mt={5}></Box>
          </form>
          <div className={classes.alert}>
          {alertSuccess && (
            <Alert variant="filled" severity="success">
              Bienvenido
            </Alert>
          )}
          {alertFail && (
            <Alert variant="filled" severity="error">
              Credenciales inválidas.
            </Alert>
          )}
        </div>
        </div>
      </Grid>
    </Grid>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
