import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import StoreIcon from "@material-ui/icons/Store";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import RedeemIcon from "@material-ui/icons/Redeem";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Axios from "../../../axios";

const useStyles = makeStyles((theme) => ({
  chip: {
    //display: "flex",
    margin: theme.spacing(0.5),
  },
  root: {
    marginTop: "10px",
  },
  card: {
    backgroundColor: "#e0e0e0",
  },
}));

export default function CardItem({ giveaway, showModal }) {
  const [sucursales, setSucursales] = React.useState([]);
  const [codigos, setCodigos] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSucursales([giveaway.Sucursales.split(",")]);
  }, [giveaway]);

  useEffect(async () => {
    let data = {
      giveaway: giveaway.id,
    };
    Axios.post(process.env.REACT_APP_API + "giveaway/getcodigos", data, {
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
      },
    })
      .then((res) => {setCodigos(res.data)})
      .catch((err) => {});
  }, [sucursales]);

  const handleExpired = () => {
    let data = {
      giveaway: giveaway.id,
    };

    Axios.put(process.env.REACT_APP_API.concat(`giveaway/setexpired`), data, {
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
      },
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          localStorage.clear();
          window.location.reload();
        }
      });
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {giveaway.descripcion}
        </Typography>
        <Typography variant="h6" component="h2">
          {giveaway.SKU}
        </Typography>
        <Divider />
        <div className={classes.root}>
          <Chip
            icon={<ShoppingCartIcon />}
            label={"Comprando: " + giveaway.litros + " litros"}
            clickable
            color="primary"
            className={classes.chip}
          />
          <Chip
            icon={<MonetizationOnIcon />}
            label={"Mas: $" + giveaway.monto_extra + " MxN"}
            clickable
            color="primary"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            icon={<RedeemIcon />}
            label={
              "Recibe: " + giveaway.recibe + " pzs " + giveaway.descripcion
            }
            clickable
            color="primary"
            className={classes.chip}
          />
          <Chip
            icon={<AnnouncementIcon />}
            label={"Limite: " + giveaway.limite_piezas + " piezas por cliente"}
            clickable
            color="primary"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            icon={<EventAvailableIcon />}
            label={"Inicio: " + giveaway.fecha_inicio}
            clickable
            color="primary"
            className={classes.chip}
          />
          <Chip
            icon={<EventBusyIcon />}
            label={"Fin: " + giveaway.fecha_final}
            clickable
            color="primary"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            icon={<StoreIcon />}
            label={"Vigente en: " + sucursales}
            clickable
            color="primary"
            className={classes.chip}
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
      {codigos.length > 0 && 
        <Button
          size="small"
          color="primary"
          startIcon={<VisibilityIcon />}
          onClick={() => showModal(codigos)}
        >
          Ver códigos participantes
        </Button>
      }
        <Button
          size="small"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={handleExpired}
        >
          Eliminar
        </Button>
      </CardActions>
    </Card>
  );
}
