import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../Components/Sidebar";
import MainMecanica from "./Promociones/MainMecanica";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import requests from "../requests";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    alignItems: "center",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function Promociones() {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <Sidebar select="Promociones" />
      <div className={classes.container}>
        <MainMecanica fetchUrl={requests.fetchMecanicas}/> 
      </div>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={Link}
        to="/CrearMecanica"
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
