import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../Components/Sidebar";
import MainBanner from "./Banners/MainBanner";
import requests from "../requests";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    marginTop: "2%",
  },
}));

export default function Banners() {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <Sidebar select="Banners" />
      <div className={classes.container}>
        <MainBanner fetchUrl={requests.fetchBanners}/>
      </div>
    </div>
  );
}
