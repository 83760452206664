import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../../Components/Sidebar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { DropzoneDialog } from "material-ui-dropzone";
import { useParams } from "react-router";
import AcordionPromocion from "./Components/AcordionPromocion";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import axios from "../../axios.js";
import Alert from "@material-ui/lab/Alert";
import formato1 from "../../assets/formatos/formato_codigos_ligados.xlsx";
import formato2 from "../../assets/formatos/formato_codigos_mecanica.xlsx";
import * as ReactBootStrap from "react-bootstrap";
import MecanicaEmpty from "./Components/MecanicaEmpty";
import { ListGroup } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    marginTop: "2%",
    textAlignLast: "center",
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  table: {
    minWidth: 300,
  },
  alert: {
    marginTop: "100px",
  },
  link: {
    color: "inherit",
    textDecoration: "inherit",
  }
}));

export default function HomeMecanica({
  fetchPromociones,
  fetchCodigosParticipantes,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [alertFail, setAlertFail] = React.useState(false);
  const [failText, setFailText] = React.useState('Error en el archivo, favor de revisarlo.')
  const [promociones, setPromociones] = React.useState([]); //Este estado lo utilizamos para las promociones X en Y y de X a Y
  const [promociones2, setPromociones2] = React.useState([]); //Este estado lo utilizamos para las promociones Precio Especial
  const [promociones3, setPromociones3] = React.useState([]); //Este estado lo utilizamos para las promociones Precio especial a partir de.
  const [codigos, setCodigos] = React.useState([]);
  const { id } = useParams();
  const mecanica_id = id;
  const [mecanica, setMecanica] = React.useState([]);
  const [openCodigos, setOpenCodigos] = React.useState(false);
  const [loading, setLoading] = React.useState(false);


  const handleClickOpenCodigos = () => {
    setOpenCodigos(true);
  };

  const handleCloseCodigos = () => {
    setOpenCodigos(false);
  };

  useEffect(() => {
    async function fetchMecanica() {
      const request = await axios.get(
        process.env.REACT_APP_API.concat("mecanica/").concat(mecanica_id)
      ,{headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((response) => {
        setMecanica(response.data);
      }).catch((e) => {
        if(e.response.status == 401){
          localStorage.clear();
          window.location.reload();
        }
      });
    }
    fetchMecanica();
  }, []);


  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchPromociones.concat(mecanica_id), {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((response) => {
        setLoading(true);
          response.data.forEach((element) => {
            if (element.tipo_id < 3) {
              setPromociones((promociones) => [...promociones, element]);
            }
            if (element.tipo_id == 3) {
              setPromociones2((promociones2) => [...promociones2, element]);
            }
            if (element.tipo_id == 4) {
              setPromociones3((promociones3) => [...promociones3, element]);
            }
          });
      }).catch((e) => {
        if(e.response.status == 401){
          localStorage.clear();
          window.location.reload();
        }
      });
    }
    fetchData();
  }, [fetchPromociones]);

  useEffect(() => {
    async function fetchCodigos() {
      const request = await axios.get(
        fetchCodigosParticipantes.concat(mecanica_id)
      ,{headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((response) => {
        setCodigos(response.data);
      }).catch((e) => {
        if(e.response.status == 401){
          localStorage.clear();
          window.location.reload();
        }
      });
    }
    fetchCodigos();
  }, [fetchCodigosParticipantes]);

  const subirArchivo = (files) => {
    const formData = new FormData();

    formData.append("file", files[0]);
    formData.append("mecanica_id", mecanica_id);

    axios
      .post(process.env.REACT_APP_API.concat("mecanica/subircodigos"), formData,{headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((res) => {
        if(res.data.success){
        setAlertSuccess(true);
        window.location.reload();
        }
        if(!res.data.success){
        setAlertFail(true);
          if(res.data.codigos_falsos){
            setFailText('Se encontró un código inexistente: '+ res.data.codigos_falsos);
          }
        }
      })
      .catch((err) => {
        setAlertFail(true);
        if(err.response?.data.status == 401){
          localStorage.clear();
          window.location.reload();
        }
      });
  };

  const handleSave = (files) => {
    setLoading(true);
    setFiles(files);
    setOpen(false);
    subirArchivo(files);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.body}>
      <Sidebar select="Promociones" />
      <div className={classes.container}>
      <div style={{alignContent: 'start', width:'100%', textAlignLast: 'start'}}>
      {codigos.length  === 0 && (
        <div>
        <h3>Instrucciones:</h3>
      <ListGroup>
      <ListGroup.Item variant="info">No subir códigos participantes de diferentes familias (Cartoneria, Garrafas, Cubetas, Tambos)</ListGroup.Item>
      <ListGroup.Item variant="info">Para descargar el formato para subir los códigos participantes da click en el botón "Descargar Formato"</ListGroup.Item>
      <ListGroup.Item variant="info">Una vez llenado el formato, da click en el botón Agregar códigos participantes para subir el archivo</ListGroup.Item>
      <ListGroup.Item variant="info">No se pueden mezclar promociones de precio especial con promociones de X a Y o De X a Y</ListGroup.Item>
      </ListGroup>
      </div>
      )}
      </div>
      <div style={{padding: '15px', textAlignLast: 'start'}}>
      <h3>Promociones: </h3>
      </div>
        {loading ? (
          promociones.length > 0 || promociones2.length > 0 || promociones3.length > 0 ? (
            promociones.map((promocion, index) => (
            <AcordionPromocion key={index} promocion={promocion} />
          ))
          ):(
            <MecanicaEmpty />
          )           
        ) : (
          <div className={classes.loading}>
            <ReactBootStrap.Spinner animation="border" variant="primary" />
          </div>
        )}
        {promociones2.length > 0 && (
          <AcordionPromocion promocion={promociones2} />
        )}
        {promociones3.length > 0 && (
          <AcordionPromocion promocion={promociones3} />
        )}
        {promociones.length >= 1 ? (
          codigos.length >= 1 ? (
            <div>
              <Button
                variant="contained"
                style={{ marginTop: "2%" }}
                color="primary"
                onClick={handleClickOpenCodigos}
              >
                Ver códigos participantes
              </Button>
            </div>
          ) : (
            <div>
              {(mecanica.familia_id == 2 ? (
              <Button variant="contained" color="primary">
                <Link to={formato1} target="_blank" className={classes.link}>
                  Descargar formato
                </Link>
              </Button>
              ) : (
                <Button variant="contained" color="primary">
                <Link to={formato2} target="_blank" className={classes.link}>
                  Descargar formato
                </Link>
              </Button>
              ))}
              <div>
                <Button
                  variant="contained"
                  style={{ marginTop: "2%" }}
                  color="primary"
                  onClick={handleClickOpen}
                >
                  Agregar códigos participantes
                </Button>
              </div>
            </div>
          )
        ) : (
          ""
        )}
        <div className={classes.alert}>
          {alertSuccess && (
            <Alert variant="filled" severity="success">
              Codigos subidos exitosamente!
            </Alert>
          )}
          {alertFail && (
            <Alert variant="filled" severity="error">
              {failText}
            </Alert>
          )}
        </div>
      </div>
      <div>
        <Dialog
          open={openCodigos}
          onClose={handleCloseCodigos}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Codigos participantes"}
          </DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  {mecanica.familia_id == 2 ? (
                    <TableRow>
                      <TableCell align="center">Codigo SAP</TableCell>
                      <TableCell align="center">Compra</TableCell>
                      <TableCell align="center">Codigo SAP</TableCell>
                      <TableCell align="center">Recibe</TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell align="center">Codigo SAP</TableCell>
                      <TableCell align="center">Descripción</TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {mecanica.familia_id == 2
                    ? Object.keys(codigos)?.map((keyName, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">
                            {codigos[keyName]?.sku_compra}
                          </TableCell>
                          <TableCell align="center">
                            {codigos[keyName]?.descripcion_compra}
                          </TableCell>
                          <TableCell align="center">
                            {codigos[keyName]?.sku_recibe}
                          </TableCell>
                          <TableCell align="center">
                            {codigos[keyName]?.descripcion_recibe}
                          </TableCell>
                        </TableRow>
                      ))
                    : Object.keys(codigos)?.map((keyName, i) => (
                        <TableRow key={i}>
                          <TableCell align="center">
                            {codigos[keyName]?.SKU}
                          </TableCell>
                          <TableCell align="center">
                            {codigos[keyName]?.descripcion}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCodigos} color="primary" autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={Link}
        to={`/Mecanicas/${mecanica_id}/CrearPromocion`}
      >
        <AddIcon />
      </Fab>
      <DropzoneDialog
        open={open}
        acceptedFiles={[".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ]}
        keyName="codigos"
        onSave={handleSave}
        filesLimit="1"
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleClose}
        dropzoneText="Arrasta o haz click para subir el archivo deseado"
      />
    </div>
  );
}
