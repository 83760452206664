import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto",
  },
  withoutLabel: {
    marginTop: theme.spacing(1),
  },
  textField: {
    width: "10ch",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  ejemplo: {
    marginTop: "15%",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function Promo2({ mecanica }) {
  const history = useHistory();
  const [alertFail, setAlertFail] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const classes = useStyles();
  const [values, setValues] = React.useState({
    valor1: "",
    valor2: "",
    valor3: "",
    unidad: "",
  });
  const [errors, setErrors] = React.useState("");
  const subirPromocion = () => {
    if (values.valor1 != "" && values.valor2 != "" && values.valor3 != "" && values.valor1 > 0 && values.valor2 > 0 && values.valor3 > 0) {
      if (values.valor1 <= values.valor2) {
        setLoading(true);
        let data = {
          mecanica_id: mecanica,
          tipo_id: "2",
          cantidad_x: values.valor1,
          cantidad_y: values.valor2,
          cantidad_z: values.valor3,
        };

        Axios.post(process.env.REACT_APP_API.concat("promocion"), data,
        {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
          .then(function (response) {
            history.push(`/Mecanicas/${mecanica}`);
          })
          .catch(function (error) {
            if(error.response.status == 401){
              localStorage.clear();
              window.location.reload();
            };
          });
      } else {
        setAlertMessage("El valor X debe ser menor al valor de Y");
        setAlertFail(true);
      }
    } else {
      setAlertMessage("Favor de llenar todos los campos.");
      setAlertFail(true);
    }
  };

  const handleChange = (prop) => (event) => {
    if(event.target.value < 0){
      event.target.value = 0;
    }
    let regL = RegExp(/^[1-9]\d*/).test(event.target.value);
    switch (event.target.name) {
      case "valor1":
        setErrors({ ...errors, valor1: "" });
        if (!regL) {
          setErrors({
            ...errors,
            valor1: "La cantidad no puede ser menor/igual a 0",
          });
          setValues({ ...values, [prop]: event.target.value });
        } else {
          setValues({ ...values, [prop]: event.target.value });
        }
        break;
      case "valor2":
        setErrors({ ...errors, valor2: "" });
        if (!regL) {
          setErrors({
            ...errors,
            valor2: "La cantidad no puede ser menor/igual a 0",
          });
          setValues({ ...values, [prop]: event.target.value });
        } else {
          setValues({ ...values, [prop]: event.target.value });
        }
        break;
      case "valor3":
        setErrors({ ...errors, valor3: "" });
        if (!regL) {
          setErrors({
            ...errors,
            valor3: "La cantidad no puede ser menor/igual a 0",
          });
          setValues({ ...values, [prop]: event.target.value });
        } else {
          setValues({ ...values, [prop]: event.target.value });
        }
        break;
      case "unidad":
        setValues({ ...values, [prop]: event.target.value });
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.loading}>
          <ReactBootStrap.Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          <Grid item xs={6} sm={3}>
            <TextField
              label="Compra de"
              id="standard-start-adornment"
              name="valor1"
              type="number"
              value={values.valor1}
              onChange={handleChange("valor1")}
              error={Boolean(errors?.valor1)}
              helperText={errors.valor1}
              className={clsx(classes.margin)}
              style={{ marginLeft: "10%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">X:</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              label="a:"
              id="standard-start-adornment"
              name="valor2"
              type="number"
              value={values.valor2}
              onChange={handleChange("valor2")}
              className={clsx(classes.margin)}
              error={Boolean(errors?.valor2)}
              helperText={errors.valor2}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Y:</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Unidad</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="unidad"
                value={values.unidad}
                className={clsx(classes.margin)}
                onChange={handleChange("unidad")}
                label="Unidad"
              >
                <MenuItem value={"Cajas de Litro"}>Cajas de Litro</MenuItem>
                <MenuItem value={"Cajas de Garrafa"}>
                  Cajas de Garrafas
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              label="y recibe:"
              id="standard-start-adornment"
              name="valor3"
              type="number"
              value={values.valor3}
              onChange={handleChange("valor3")}
              error={Boolean(errors?.valor3)}
              helperText={errors.valor3}
              className={clsx(classes.margin)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Z:</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlignLast: "center" }}>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "500",
                fontStyle: "normal",
                fontFamily: "Roboto",
                marginTop: "2%",
                justifyContent: "center",
              }}
            >
              Compra de {values.valor1} a {values.valor2} {values.unidad} y
              recibe {values.valor3} <RenderSwitch {...values} /> por cada caja
              que compres
            </Typography>
          </Grid>
          {alertFail && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {alertMessage}
              </Alert>
            </Grid>
          )}
        </Grid>
      )}

      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={Link}
        onClick={subirPromocion}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

function RenderSwitch(param) {
  switch (param.unidad) {
    case "Cajas de Litro":
      return "Litro(s)";
    case "Cajas de Garrafa":
      return "Garrafa(s)";
    default:
      return "";
  }
}
