import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Promo4({ mecanica }) {
  const history = useHistory();
  const classes = useStyles();
  const [values, setValues] = React.useState([]);
  const [sku, setSku] = React.useState("");
  const [precio, setPrecio] = React.useState("");
  const [multiplicador, setMultiplicador] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [alertFail, setAlertFail] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertWarning, setAlertWarning] = React.useState(false);
  const [warningMessage, setWarningMessage] = React.useState("");

  const subirPromocion = () => {
    let data = {
      mecanica_id: mecanica,
      tipo_id: "4",
      values: values,
    };


    Axios.post(process.env.REACT_APP_API.concat("promocion"), data, 
    {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then(function (response) {
        history.push(`/Mecanicas/${mecanica}`);
      })
      .catch(function (error) {
        if(error.response.status == 401){
          localStorage.clear();
          window.location.reload();
        };
      });
  };

  const handleChange1 = (prop) => (event) => {
    setSku({
      ...sku,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange2 = (prop) => (event) => {
    setPrecio({
      ...precio,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange3 = (prop) => (event) => {
    setMultiplicador({
      ...multiplicador,
      [event.target.name]: event.target.value,
    });
  };

  const addValue = (props) => (event) => {
    setAlertFail(false);
    event.preventDefault();
    var repeated = false;

    if (values.length > 0) {
      values.forEach((element) => {
        if (element.sku == sku.sku) {
          repeated = true;
        } else {
          setAlertWarning(false);
        }
      });
    }

    if (multiplicador && sku && precio) {
      if (!repeated) {
        setLoading(true);
        const data = {
          codigo_sap: sku.sku
        }
        if(data.codigo_sap.charAt(data.codigo_sap.length -1) === 'Q'){
          Axios.post(
            process.env.REACT_APP_API.concat("producto/search"), data
            ,{headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}}
          )
            .then((response) => {
              if (response.data !== false) {
                setValues([
                  ...values,
                  {
                    sku: sku.sku,
                    precio: precio.precio,
                    multiplicador: multiplicador.z,
                  },
                ]);
              }
              if (response.data == false) {
                setAlertMessage("El SKU " + sku.sku + " es invalido.");
                setAlertFail(true);
              }
            })
            .catch((e) => {
              if(e.response.status == 401){
                localStorage.clear();
                window.location.reload();
              }
            });
        }else{
          setAlertMessage('El SKU '+ sku.sku+' debe finalizar en Q.');
          setAlertFail(true);
        }
      } else {
        setWarningMessage('SKU repetido.');
        setAlertWarning(true);
      }
    } else {
      setAlertMessage("Favor de llenar todos los campos");
      setAlertFail(true);
    }
    setLoading(false);
  };

  const removeData = (sku) => {
    const del = values.filter((value) => sku !== value.sku);
    setValues(del);
  };

  const renderHeader = () => {
    let headerElement = ["sku", "precio", "a partir de", "borrar"];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const renderBody = () => {
    return (
      values &&
      values.map(({ sku, precio, multiplicador }) => {
        return (
          <tr key={sku}>
            <td>{sku}</td>
            <td>{precio}</td>
            <td>{multiplicador}</td>
            <td className="opration">
              <button className="button" onClick={() => removeData(sku)}>
                Delete
              </button>
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.loading}>
          <ReactBootStrap.Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          <Grid container spacing={2} style={{ justifyContent: "center" }}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="sku"
                name="sku"
                id="standard-start-adornment"
                value={sku.value}
                onChange={handleChange1("sku")}
                className={clsx(classes.margin)}
                style={{ marginLeft: "10%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">X:</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Precio"
                value={precio.value}
                onChange={handleChange2("precio")}
                name="precio"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Z"
                name="z"
                id="standard-start-adornment"
                value={multiplicador.value}
                onChange={handleChange3("z")}
                className={clsx(classes.margin)}
                style={{ marginLeft: "10%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      A partir de:
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addValue(values)}
                >
                  Añadir
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: "center",
              marginLeft: "4%",
              marginTop: "4%",
              textAlign: "-webkit-center",
            }}
          >
            <Grid item xs={6} sm={0}>
              <h1 id="title">Precio especial a partir de</h1>
              <table id="precios">
                <thead>
                  <tr>{renderHeader()}</tr>
                </thead>
                <tbody>{renderBody()}</tbody>
              </table>
            </Grid>
          </Grid>
          {alertFail && (
            <Grid item xs={12} style={{ marginTop: "15px" }}>
              <Alert variant="filled" severity="error">
                {alertMessage}
              </Alert>
            </Grid>
          )}
        </div>
      )}
      {alertWarning && (
            <Grid item xs={12} style={{marginTop: '15px'}}>
              <Alert variant="filled" severity="warning">
                {warningMessage}
              </Alert>
            </Grid>
          )}
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={Link}
        onClick={subirPromocion}
        to={`/Mecanicas/${mecanica}`}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
