import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import image from "../../../assets/Lub.png";

export default function MecanicaEmpty() {
  return (
    <Grid>
      <Grid style={{paddingTop: '20px', justifyItems: 'center', alignSelf: 'center', textAlignLast: 'center'}}>
        <Typography component="h1" variant="h5">
          ¡Ups! Parece que aquí	no hay nada...
        </Typography>
        <Typography component="h1" variant="h5">
          ¡Pulsa el botón '+' para empezar a crear!
        </Typography>
      </Grid>
      <Grid style={{marginTop: '30px'}}>
        <img src={image} />
      </Grid>
    </Grid>
  );
}
