import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "./Tabla.css";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Promo3({ mecanica }) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [sku, setSku] = React.useState("");
  const [precio, setPrecio] = React.useState("");
  const [values, setValues] = React.useState([]);
  const [alertFail, setAlertFail] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertWarning, setAlertWarning] = React.useState(false);
  const [warningMessage, setWarningMessage] = React.useState("");

  const subirPromocion = () => {
    setLoading(true);
    let data ={
      mecanica_id: mecanica,
      tipo_id: "3",
      values: values,
    };

    Axios.post(process.env.REACT_APP_API.concat("promocion"), data,
    {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then(function (response) {
        history.push(`/Mecanicas/${mecanica}`);
      })
      .catch(function (error) {
        if(error.response.status == 401){
          localStorage.clear();
          window.location.reload();
        };
      });
  };

  const handleChange1 = (prop) => (event) => {
    setSku({
      ...sku,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange2 = (prop) => (event) => {
    setPrecio({
      ...precio,
      [event.target.name]: event.target.value,
    });
  };


  const addValue = (prop) => (event) => {
    event.preventDefault();
    var repeated = false;

    if(values.length > 0){
      values.forEach(element => {
        if(element.sku == sku.SKU){
          repeated = true;
        }else{
          setAlertWarning(false);
        }
      });
    }

    if(!repeated){
      if(precio.numberformat && sku.SKU){
        setLoading(true);
        const data = {
          codigo_sap: sku.SKU
        }
        //(producto.SKU).charAt(producto.SKU.length - 1) === 'G'
        if(data.codigo_sap.charAt(data.codigo_sap.length -1) === 'P'){
          Axios.post(process.env.REACT_APP_API.concat('producto/search'), data,
      {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
    .then(response => {
      if(response.data !== false){
          setAlertFail(false);
          setValues([
            ...values,
            {
              sku: sku.SKU,
              precio: precio.numberformat,
            },
          ]); 
      }
      if(response.data == false){
        setAlertMessage('El SKU '+ sku.SKU+' es invalido.');
        setAlertFail(true);
      }
    })
    .catch(e => {
      if(e.response.status == 401){
        localStorage.clear();
        window.location.reload();
      }
    })
    }else{
      setAlertMessage('El SKU '+ sku.SKU+' debe finalizar en P.');
      setAlertFail(true);
    }
      }else{
        setAlertMessage('Favor de llenar todos los campos.');
        setAlertFail(true);
      }
    }else{
      setWarningMessage('SKU repetido.');
      setAlertWarning(true);
    }
    setLoading(false);
  };

  const removeData = (sku) => {
    const del = values.filter((value) => sku !== value.sku);
    setValues(del);
  };

  const renderHeader = () => {
    let headerElement = ["sku", "precio", "borrar"];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const renderBody = () => {
    return (
      values &&
      values.map(({ sku, precio }) => {
        return (
          <tr key={sku}>
            <td>{sku}</td>
            <td>{precio}</td>
            <td className="opration">
              <button className="button" onClick={() => removeData(sku)}>
                Delete
              </button>
            </td>
          </tr>
        );
      })
    );
  };
  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.loading}>
          <ReactBootStrap.Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          <Grid container spacing={2} style={{ justifyContent: "center" }}>
            <Grid item xs={6} sm={3}>
              <TextField
                label="SKU"
                name="SKU"
                id="standard-start-adornment"
                value={values.sku}
                onChange={handleChange1("sku")}
                className={clsx(classes.margin)}
                style={{ marginLeft: "10%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">X:</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                label="Precio"
                value={values.numberformat}
                onChange={handleChange2("numberformat")}
                name="numberformat"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addValue(values)}
                >
                  Añadir
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            style={{
              justifyContent: "center",
              marginLeft: "4%",
              marginTop: "4%",
              textAlign: "-webkit-center",
            }}
          >
            <Grid item xs={6} sm={0}>
              <h1 id="title">Precio especial</h1>
              <table id="precios">
                <thead>
                  <tr>{renderHeader()}</tr>
                </thead>
                <tbody>{renderBody()}</tbody>
              </table>
            </Grid>
          </Grid>
          {alertFail && (
            <Grid item xs={12} style={{marginTop: '15px'}}>
              <Alert variant="filled" severity="error">
                {alertMessage}
              </Alert>
            </Grid>
          )}
          {alertWarning && (
            <Grid item xs={12} style={{marginTop: '15px'}}>
              <Alert variant="filled" severity="warning">
                {warningMessage}
              </Alert>
            </Grid>
          )}
        </div>
      )}

      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={Link}
        onClick={subirPromocion}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
