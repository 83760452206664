import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 650,
  },
}));

export default function AcordionPromocion({ promocion }) {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        {promocion.tipo_id < 3 ? (
          <Typography className={classes.heading}>
            {promocion.descripcion}
          </Typography>
        ) : (
          <Typography className={classes.heading}>
            {promocion[0]?.descripcion}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {promocion.tipo_id < 3 ? (
          promocion.tipo_id == 1 ? (
            <Typography>
              Compra {promocion.cantidad_x} (Cajas Litro ó Cajas de Garrafas ó Cubetas ó Tambos) y se te regala{" "}
              {promocion.cantidad_y} unidad (Caja de Litro |Caja de Garrafas | Cubeta's' | Cubeta ó Garrafa del producto comprado).
            </Typography>
          ) : (
            <Typography>
              Compra de {promocion.cantidad_x} a {promocion.cantidad_y} (Cajas Litro ó Cajas de Garrafas) y se te
              regala {promocion.cantidad_z} Litro(s) ó Garrafas(s) por cada unidad que compres.
            </Typography>
          )
        ) : (
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Codigo SAP</TableCell>
                  <TableCell align="right">Precio</TableCell>
                  {promocion[0]?.tipo_id == 4 ? (
                    <TableCell align="right">A partir de</TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(promocion)?.map((keyName, i) => (
                  <TableRow key={i}>
                    <TableCell align="right">
                      {promocion[keyName].SKU}
                    </TableCell>
                    <TableCell align="right">
                      ${promocion[keyName].precio}
                    </TableCell>
                    {promocion[0]?.tipo_id == 4 ? (
                      <TableCell align="right">
                        {promocion[keyName].apartir_de}
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
