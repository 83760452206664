import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Sidebar from "../Components/Sidebar";
import MainKilometros from "./Kilometros/MainKilometros";
import requests from "../requests";

const useStyles = makeStyles((theme) => ({
    body: {
      display: "flex",
      width: "100%",
      height: '100vh',
      backgroundColor: "#eeeeee"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "95%",
      marginLeft: "2%",
      marginRight: "2%",
      marginTop: "2%",

    },
  }));  

export default function Promociones() {

    const classes = useStyles();

  return (
    <div className={classes.body}>
    <Sidebar select="Kilometros" />
    <div className={classes.container}>
      <MainKilometros fetchUrl={requests.fetchKilometros}/>
    </div>
  </div>

  );
}
