import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalOffer from "@material-ui/icons/LocalOffer";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import SpeedIcon from "@material-ui/icons/Speed";
import RedeemIcon from "@material-ui/icons/Redeem";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    list: {
      width: 310,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "2px 0 5px -2px #888;",
      zIndex: "1",
      backgroundColor: "#ffffff",
    },
  }));
  

export default function Sidebar(props) {
    const classes = useStyles();
    const history = useHistory();
    return (
    <div className={classes.list} role="presentation">
      <List style={{ fontWeight: "bold", color: "black", fontSize:"large", padding:"0px"}}>
        <ListItem
          button
          key="Promociones"
          style={
            props.select === "Promociones"
              ? { backgroundColor: "#cfd8dc", color: "black",textAlign:"center",padding:"1vh",fontWeight:"bold" }
              : {textAlign:"center",padding:"1vh",fontWeight:"bold"}
          }
          onClick={() => history.push(`/`)}
        >
          <ListItemIcon>
            <LocalOffer
              style={{ color: "black" }}
            />
          </ListItemIcon>
          <ListItemText primary="Promociones" />
        </ListItem>
        <Divider style={{ backgroundColor: "#9ea7aa", width: "100%", alignSelf:"center" }} />
        <ListItem
          button
          key="Giveaways"
          style={
            props.select === "Giveaways"
              ? { backgroundColor: "#cfd8dc", color: "black",textAlign:"center",padding:"1vh",fontWeight:"bold" }
              : {textAlign:"center",padding:"1vh",fontWeight:"bold"}
          }
          onClick={() => history.push(`/MainGiveaway`)}
        >
          <ListItemIcon>
            <RedeemIcon
              style={{color: 'black'}}
            />
          </ListItemIcon>
          <ListItemText primary="Giveaways" />
        </ListItem>
        <Divider style={{ backgroundColor: "#9ea7aa", width: "100%", alignSelf:"center" }} />
        <ListItem
          button
          key="Banners"
          style={
            props.select === "Banners"
              ? { backgroundColor: "#cfd8dc", color: "black",textAlign:"center",padding:"1vh",fontWeight:"bold" }
              : {textAlign:"center",padding:"1vh",fontWeight:"bold"}
          }
          onClick={() => history.push(`/Banners`)}
        >
          <ListItemIcon>
            <WallpaperIcon
              style={{color: 'black'}}
            />
          </ListItemIcon>
          <ListItemText primary="Banners" />
        </ListItem>
        <Divider style={{ backgroundColor: "#9ea7aa", width: "100%", alignSelf:"center" }} />
        <ListItem
          button
          key="Kilometros"
          style={
            props.select === "Kilometros"
              ? { backgroundColor: "#cfd8dc", color: "black",textAlign:"center",padding:"1vh",fontWeight:"bold" }
              : {textAlign:"center",padding:"1vh",fontWeight:"bold"}
          }
          onClick={() => history.push(`/Kilometros`)}
        >
          <ListItemIcon>
            <SpeedIcon
              style={{color: 'black'}}
            />
          </ListItemIcon>
          <ListItemText primary="Kilometros" />
        </ListItem>
      </List>
    </div>
  );
}
