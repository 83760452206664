import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { Typography } from "@material-ui/core";
import "fontsource-roboto";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Sidebar from "../../Components/Sidebar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import * as ReactBootStrap from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import axios from "../../axios";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    marginTop: "2%",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  stepper: {
    minWidth: 600,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  loading: {
    justifyContent: "center",
    alignSelf: "center",
    margin: "auto",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  left: {},
}));

export default function Step1(props) {
  let history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [familia, setFamilia] = React.useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [errors, setErrors] = React.useState("");
  const [nombre, setNombre] = React.useState();
  const [sucursales, setSucursales] = React.useState({
    TRA1: false,
    TRA2: false,
    TRA3: false,
    TRA5: false,
    TRA6: false,
    TRA7: false,
    TRA8: false,
    TRA9: false,
  });
  const [suc, setSuc] = React.useState([]);
  const [alertFail, setAlertFail] = React.useState(false);

  console.log('nombre', nombre);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "familia":
        setFamilia(event.target.value);
        break;
      case "fecha_inicio":
        setStartDate(event.target.value);
        break;
      case "fecha_fin":
        if (event.target.value < startDate) {
          setEndDate(event.target.value);
          setErrors({
            fin: "El valor del final no puede ser menor al de inicio",
          });
        } else {
          setErrors("");
          setEndDate(event.target.value);
        }
        break;
      default:
        break;
    }
  };

  const handleSuc = (event) => {
    setSucursales({ ...sucursales, [event.target.name]: event.target.checked });
    setSuc([...suc, event.target.value]);
  };


  const handleButton = () => {
    var pass = false;
    if (familia && startDate && endDate && suc.length >= 1) {
      if (sucursales.TRA1) {
        pass = true;
      }
      if (sucursales.TRA2) {
        pass = true;
      }
      if (sucursales.TRA3) {
        pass = true;
      }
      if (sucursales.TRA5) {
        pass = true;
      }
      if (sucursales.TRA6) {
        pass = true;
      }
      if (sucursales.TRA7) {
        pass = true;
      }
      if (sucursales.TRA8) {
        pass = true;
      }
      if (sucursales.TRA9) {
        pass = true;
      }
      if (endDate > startDate) {
        if(pass){
          if(nombre){
            setLoading(true);
        
            let data = {
            familia_id: familia,
            fecha_inicio: startDate,
            fecha_fin: endDate,
            sucursales: suc,
            nombre: nombre
          };
  
          const request = axios.post('mecanica', data,{headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
            .then(function (response) {
              history.push("/");
            })
            .catch(function (error) {
              if(error.response.status == 401){
                localStorage.clear();
                window.location.reload();
              };
            });
          }else{
            setAlertFail(true);
          }
        } else{
          setAlertFail(true);
        }
      } else {
        setAlertFail(true);
      }
    } else {
      setAlertFail(true);
    }
  };

  return (
    <div className={classes.body}>
      <Sidebar select="Promociones" />
      <div className={classes.container}>
        {loading ? (
          <div className={classes.loading}>
            <ReactBootStrap.Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Grid container spacing={3}>
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignContent="center"
            >
              <Typography
                style={{
                  fontSize: "19px",
                  fontStyle: "normal",
                }}
              >
                Familia:
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-start">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Familia
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="familia"
                  value={familia || ""}
                  onChange={handleChange}
                  label="Familia"
                >
                  <MenuItem value={1}>Cartoneria</MenuItem>
                  <MenuItem value={2}>Tambos</MenuItem>
                  <MenuItem value={3}>Cubetas</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignContent="center"
            >
              <Typography
                style={{
                  fontSize: "19px",
                  fontStyle: "normal",
                }}
              >
                Nombre:
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-start">
            <TextField 
            id="outlined-multiline-basic" 
            label="Nombre de la promoción" 
            variant="outlined" 
            multiline
            value={nombre}
            onChange={(event) => setNombre(event.target.value)}
            rows={3} 
            />
            </Grid>

            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignContent="center"
            >
              <Typography
                style={{
                  fontSize: "19px",
                  fontStyle: "normal",
                }}
              >
                Inicio de la promoción:
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-start">
              <TextField
                id="date"
                label="Inicio de la Promoción"
                type="date"
                name="fecha_inicio"
                onChange={handleChange}
                value={startDate || ""}
                defaultValue="0000-00-00"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignContent="center"
            >
              <Typography
                style={{
                  fontSize: "19px",
                  fontStyle: "normal",
                }}
              >
                Fin de la promoción:
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-start">
              <TextField
                id="date"
                label="Fin de la promoción"
                type="date"
                name="fecha_fin"
                onChange={handleChange}
                value={endDate || ""}
                error={Boolean(errors?.fin)}
                helperText={errors.fin}
                defaultValue="0000-00-00"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              container
              justify="flex-end"
              alignContent="center"
            >
              <Typography
                style={{
                  fontSize: "19px",
                  fontStyle: "normal",
                }}
              >
                Sucursales:
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="center" direction="column">
              <Grid item xs={6} container>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sucursales.TRA1}
                        onChange={handleSuc}
                        name="TRA1"
                        color="primary"
                        value="1"
                      />
                    }
                    label="Tra1"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sucursales.TRA2}
                        onChange={handleSuc}
                        name="TRA2"
                        color="primary"
                        value="2"
                      />
                    }
                    label="Tra2"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sucursales.TRA3}
                        onChange={handleSuc}
                        name="TRA3"
                        color="primary"
                        value="3"
                      />
                    }
                    label="Tra3"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sucursales.TRA5}
                        onChange={handleSuc}
                        name="TRA5"
                        color="primary"
                        value="4"
                      />
                    }
                    label="Tra5"
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} container>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sucursales.TRA6}
                        onChange={handleSuc}
                        name="TRA6"
                        color="primary"
                        value="5"
                      />
                    }
                    label="Tra6"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sucursales.TRA7}
                        onChange={handleSuc}
                        name="TRA7"
                        color="primary"
                        value="6"
                      />
                    }
                    label="Tra7"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sucursales.TRA8}
                        onChange={handleSuc}
                        name="TRA8"
                        color="primary"
                        value="7"
                      />
                    }
                    label="Tra8"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sucursales.TRA9}
                        onChange={handleSuc}
                        name="TRA9"
                        color="primary"
                        value="8"
                      />
                    }
                    label="Tra9"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={9}
              container
              justify="flex-end"
              alignContent="center"
            >
              {alertFail && (
                <Grid item xs={6} justify="flex-end" alignContent="center">
                  <Alert variant="filled" severity="error">
                    <Typography>
                      Favor de llenar todos los campos correctamente.
                    </Typography>
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          component={Link}
          onClick={handleButton}
        >
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
}
