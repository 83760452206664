import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { ErrorSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: 'precio',
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Valores(props) {
  const classes = useStyles();

  const [litros, setLitros] = React.useState("");
  const [precio, setPrecio] = React.useState("");
  const [cantidad, setCantidad] = React.useState("");
  const [giveaway, setGiveaway] = React.useState("");
  const [limite, setLimite] = React.useState("");
  const [errors, setErrors] = React.useState("");

   
  const handleChange = (event) => {
    if(event.target.value < 0){
      event.target.value = 0;
    }
     switch(event.target.name){
       case "litros":
        {props.setState('litros',event.target.value)}
         break;
       case "precio":
          setPrecio(event.target.value);
          {props.setState('precio',event.target.value)};
         break;
       case "recibe":
           setCantidad(event.target.value);
           {props.setState('cantidad',event.target.value)};
         break;
       case "giveaway":
         setGiveaway(event.target.value);
         break;
       case "limite":
         setLimite(event.target.value);
         {props.setState('limite',event.target.value)};
         break;
       default:
         break;  
     }
   };

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <TextField
          label="Por cada"
          id="standard-start-adornment"
          value={props.getState("litros")}
          name="litros"
          type="number"
          min="0" 
          step="1"
          onInput="validity.valid||(value='');"
          onChange={handleChange}
          error={Boolean(errors?.litros)}
          helperText={errors.litros}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Litros:</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Mas $"
          value={props.getState("precio")}          
          name="precio"
          onChange={handleChange}
          style={{ width: "100%" }}
          error={Boolean(errors?.precio)}
          helperText={errors.precio}
          id="formatted-numberformat-input"
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Recibe"
          id="standard-start-adornment"
          value={props.getState("cantidad")}
          name="recibe"
          type="number"
          onChange={handleChange}
          error={Boolean(errors?.recibe)}
          helperText={errors.recibe}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Cantidad:</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Codigo SAP Giveaway"
          id="standard-start-adornment"
          name="giveaway"
          value={props.getState('giveaway','')}
          onChange={props.handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Giveaway:</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ textAlignLast: "center" }}>
        <TextField
          label="Limite"
          id="standard-start-adornment"
          name="limite"
          type="number"
          value={props.getState("limite")}
          onChange={handleChange}
          error={Boolean(errors?.limite)}
          helperText={errors.limite}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Limite:</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ textAlignLast: "center" }}>
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "500",
            fontStyle: "normal",
            fontFamily: "Roboto",
            marginTop: "2%",
            justifyContent: "center",
          }}
        >
        Por cada {props.getState('litros')} litros + $ {props.getState('precio')} pesos, recibe {props.getState("cantidad")} piezas de {props.getState("giveaway")} {props.getState("descripcion")}.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlignLast: "center" }}>
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "500",
            fontStyle: "normal",
            fontFamily: "Roboto",
            justifyContent: "center",
          }}
        >
        Limite: {props.getState('limite')} piezas por cliente
        </Typography>
      </Grid>
    </Grid>
  );
}
