import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import { useHistory } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  alert: {
    marginTop: "100px",
  },
}));

export default function Sucursales(props) {
  const classes = useStyles();
  const [alertSuccess, setAlertSuccess] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [alertFail, setAlertFail] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [agregarCodigos, setAgregarCodigos] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  let history = useHistory();
  const [sucursales, setSucursales] = React.useState({
    checked1: false,
    checked2: false,
    checked3: false,
    checked5: false,
    checked6: false,
    checked7: false,
    checked8: false,
    checked9: false,
  });

  const [suc, setSuc] = React.useState([]);

  const handleChange = (event) => {
    setSucursales({ ...sucursales, [event.target.name]: event.target.checked });
    setSuc([...suc, event.target.value]);
    props.setState("sucursales", sucursales);
  };

  const subirGiveaway = () => {
    var pass = false;
    if (props.getState("sucursales")) {
      if (sucursales.checked1) {
        pass = true;
      }
      if (sucursales.checked2) {
        pass = true;
      }
      if (sucursales.checked2) {
        pass = true;
      }
      if (sucursales.checked3) {
        pass = true;
      }
      if (sucursales.checked5) {
        pass = true;
      }
      if (sucursales.checked6) {
        pass = true;
      }
      if (sucursales.checked7) {
        pass = true;
      }
      if (sucursales.checked8) {
        pass = true;
      }
      if (sucursales.checked9) {
        pass = true;
      }
      if (pass) {
        setLoading(true);
        if(agregarCodigos && files.length > 0){
          const formData = new FormData();
          var sucursales_arr = JSON.stringify(suc);

          formData.append("file", files[0]);
          formData.append("litros", props.getState("litros"))
          formData.append("monto_extra", props.getState("precio"))
          formData.append("recibe", props.getState("cantidad"))
          formData.append("codigo_sap", props.getState("giveaway"))
          formData.append("limite_piezas", props.getState("limite"))
          formData.append("fecha_inicio", props.getState("inicio"))
          formData.append("fecha_fin", props.getState("fin"))
          formData.append("sucursales", sucursales_arr)
          Axios.post(process.env.REACT_APP_API.concat("giveaway"), formData, {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
            },
          })
            .then(function (response) {
              setAlertSuccess(true);
              history.push("/MainGiveaway");
            })
            .catch(function (error) {
              //etAlertFail(true);
              setLoading(false);
            });

        }else if(agregarCodigos && files.length === 0){
          setAlertFail(true);
        }
        if(!agregarCodigos){
          var sucursales_arr = JSON.stringify(suc);
          let data = {
            litros: props.getState("litros"),
            monto_extra: props.getState("precio"),
            recibe: props.getState("cantidad"),
            codigo_sap: props.getState("giveaway"),
            limite_piezas: props.getState("limite"),
            fecha_inicio: props.getState("inicio"),
            fecha_fin: props.getState("fin"),
            sucursales: sucursales_arr,
          };
  
          Axios.post(process.env.REACT_APP_API.concat("giveaway"), data, {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
            },
          })
            .then(function (response) {
              setAlertSuccess(true);
              history.push("/MainGiveaway");
            })
            .catch(function (error) {
              //etAlertFail(true);
              setLoading(false);
            });
        }
      } else {
        setAlertFail(true);
      }
    } else {
      setAlertFail(true);
    }
  };

  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sucursales.checked1}
                onChange={handleChange}
                name="checked1"
                color="primary"
                value="1"
              />
            }
            label="Tra1"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sucursales.checked2}
                onChange={handleChange}
                name="checked2"
                color="primary"
                value="2"
              />
            }
            label="Tra2"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sucursales.checked3}
                onChange={handleChange}
                name="checked3"
                color="primary"
                value="3"
              />
            }
            label="Tra3"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sucursales.checked5}
                onChange={handleChange}
                name="checked5"
                color="primary"
                value="4"
              />
            }
            label="Tra5"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sucursales.checked6}
                onChange={handleChange}
                name="checked6"
                color="primary"
                value="5"
              />
            }
            label="Tra6"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sucursales.checked7}
                onChange={handleChange}
                name="checked7"
                color="primary"
                value="6"
              />
            }
            label="Tra7"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sucursales.checked8}
                onChange={handleChange}
                name="checked8"
                color="primary"
                value="7"
              />
            }
            label="Tra8"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sucursales.checked9}
                onChange={handleChange}
                name="checked9"
                color="primary"
                value="8"
              />
            }
            label="Tra9"
          />
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <div>
      {loading ? (
        <div className={classes.loading}>
          <ReactBootStrap.Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={5} style={{ marginTop: "1%" }}>
            <Typography style={{ textAlign: "end" }}>
              Elegir sucursales:
            </Typography>
          </Grid>
          <Grid container item xs={4} spacing={0}>
            <FormRow />
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {agregarCodigos ? (
              <Grid container item xs={12} style={{flexDirection: "row"}}>
                <Grid container item xs={6} style={{ justifyContent: "end" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agregarCodigos}
                        name="addcodes"
                        color="primary"
                        onChange={(event) =>
                          setAgregarCodigos(event.target.checked)
                        }
                      />
                    }
                    label="Agregar códigos participantes"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenDialog(true)}
                  >
                    Subir códigos participantes
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container item xs={12} style={{justifyContent: "center"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agregarCodigos}
                      name="addcodes"
                      color="primary"
                      onChange={(event) =>
                        setAgregarCodigos(event.target.checked)
                      }
                    />
                  }
                  label="Agregar códigos participantes"
                />
              </Grid>
            )}
          </Grid>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            component={Link}
            onClick={subirGiveaway}
          >
            <DoneIcon />
          </Fab>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "center" }}>
              Por cada {props.getState("litros")} litros + ${" "}
              {props.getState("precio")} pesos, recibe{" "}
              {props.getState("cantidad")} piezas de{" "}
              {props.getState("giveaway")} {props.getState("descripcion")}.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "center" }}>
              Límite: {props.getState("limite")} piezas por cliente
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ textAlign: "center" }}>
              Vigente del {props.getState("inicio")} al {props.getState("fin")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <div className={classes.alert}>
        {alertSuccess && (
          <Alert variant="filled" severity="success">
            ¡Giveaway subido con éxito!
          </Alert>
        )}
        {alertFail && (
          <Alert variant="filled" severity="error">
            Tienes que escoger por lo menos una sucursal.
          </Alert>
        )}
      </div>
      <div>
      <DropzoneDialog
        open={openDialog}
        acceptedFiles={[".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ]}
        dialogTitle="Codigos participantes: Giveaways"
        keyName="codigos"
        onSave={(files) => {
          setFiles(files)
          setOpenDialog(false)
          }}
        filesLimit="1"
        showPreviews={true}
        maxFileSize={5000000}
        onClose={() => setOpenDialog(false)}
        dropzoneText="Arrasta o haz click para subir el formato codigos_mecanica"
      />
      </div>
    </div>
  );
}
