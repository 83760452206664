import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../../Components/Sidebar";
import { DropzoneArea } from "material-ui-dropzone";
import Grid from "@material-ui/core/Grid";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import PublishIcon from "@material-ui/icons/Publish";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import file from "../../assets/formatos/formato_kilometros.xlsx";
import Axios from "axios";
import * as ReactBootStrap from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { forEach, map } from "lodash";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
    backgroundColor: "#eeeeee",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    marginTop: "2%",
  },
  item: {
    marginTop: "15%",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },
}));

export default function UploadKilometros() {
  let history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [inicio, setInicio] = React.useState();
  const [fin, setFin] = React.useState();
  const [errors, setErrors] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [alertFail, setAlertFail] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [codigos, setCodigos] = React.useState([]);

  const subirKilometros = () => {
    if (inicio && fin && files.length > 0) {
      if (fin > inicio) {
        setLoading(true);
        const formData = new FormData();

        formData.append("file", files[0]);
        formData.append("fecha_inicio", inicio);
        formData.append("fecha_fin", fin);

        Axios.post(process.env.REACT_APP_API.concat("kilometro"), formData, {
          headers: {
            Authorization:
              "Bearer " + localStorage.getItem("token").replace(/"/g, ""),
          },
        })
          .then((res) => {
            if (res.data.success == true) {
              if (res.data.code == 100) {
                setOpen(true);
                setCodigos(res.data.codigos);
              } else {
                history.push("/Kilometros");
              }
            } else {
              setAlertMessage(res.data.message);
              setAlertFail(true);
              setLoading(false);
            }
          })
          .catch((err) => {
          });
      } else {
        setAlertMessage("La fecha de fin no puede ser menor a la de inicio");
        setAlertFail(true);
      }
    } else {
      setAlertMessage("Favor de llenar todos los campos");
      setAlertFail(true);
    }
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case "inicio":
        setInicio(event.target.value);
        break;
      case "fin":
        if (event.target.value < inicio) {
          setFin(event.target.value);
          setErrors({
            fin: "El valor del final no puede ser menor al de inicio",
          });
        } else {
          setErrors("");
          setFin(event.target.value);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.body}>
      <Sidebar select="Kilometros" />
      <div className={classes.container}>
        {loading ? (
          <ReactBootStrap.Spinner animation="border" variant="primary" />
        ) : (
          <Grid container spacing={5}>
            <Grid item xs={8}>
              <DropzoneArea
                Icon={AttachFileIcon}
                dropzoneText={"Sube el formato de los kilometros aquí"}
                acceptedFiles={[
                  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                ]}
                onChange={(files) => setFiles(files)}
                onAlert={(message, variant) =>
                  console.log(`${variant}: ${message}`)
                }
              />
            </Grid>
            <Grid item xs={3}>
              <React.Fragment>
                <form noValidate autoComplete="off">
                  <Grid item xs={8}>
                    <Typography className={classes.item}>
                      Vigencia inicio:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="date"
                      type="date"
                      name="inicio"
                      value={inicio}
                      onChange={handleChange}
                      style={{ width: "210px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.item}>
                      Vigencia Fin:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="date"
                      type="date"
                      name="fin"
                      value={fin}
                      onChange={handleChange}
                      error={Boolean(errors?.fin)}
                      helperText={errors.fin}
                      style={{ width: "210px" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: "10%" }}>
                    <Button variant="contained" color="primary">
                      <Link to={file} target="_blank" className={classes.link}>
                        Descargar formato
                      </Link>
                    </Button>
                  </Grid>
                </form>
              </React.Fragment>
            </Grid>
            <Fab
              color="primary"
              aria-label="add"
              className={classes.fab}
              component={Link}
              onClick={subirKilometros}
            >
              <PublishIcon />
            </Fab>
          </Grid>
        )}
        {alertFail && (
          <Alert variant="filled" severity="error">
            {alertMessage}
          </Alert>
        )}
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"¡Atención!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Los siguientes productos no fueron encontrados en nuestra base de
              datos, favor de reportarlo a soporte.
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
             <ul> 
             {codigos.map((value) => (
              <li>{value}</li>
             ))}
            </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => history.push("/Kilometros")}
              color="primary"
              autoFocus
            >
              Entendido
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
