import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Img from "../../../assets/banners/banner1.jpg";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import StoreIcon from "@material-ui/icons/Store";
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Axios from "../../../axios";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
  },
  media: {
    height: 183,
    width: 414,
  },
  card: {
    backgroundColor: "#e0e0e0",
  },
  chip: {
    //display: "flex",
    margin: theme.spacing(0.5),
  },
}));

export default function Card1({ banner }) {
  const classes = useStyles();

  const handleExpired = () => {
    let data = {
      banner: banner.id,
    };
    Axios.put(process.env.REACT_APP_API.concat(`banner/setexpired`), data, {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}}
    )
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
      });
  };

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={banner.banner_url}
          title={banner.titulo}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {banner.titulo}
          </Typography>
          <Divider />
          <Chip
            icon={<EventAvailableIcon />}
            label={"Inicio: " + banner.fecha_inicio}
            clickable
            color="primary"
            className={classes.chip}
          />
          <Chip
            icon={<EventBusyIcon />}
            label={"Fin: " + banner.fecha_fin}
            clickable
            color="primary"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            icon={<StoreIcon />}
            label={"Vigente en: " + banner.Sucursales}
            clickable
            color="primary"
            className={classes.chip}
          />
        </CardContent>
      </CardActionArea>
      <Divider />
      <CardActions>
        <Button 
        size="small" 
        color="secondary" 
        startIcon={<DeleteIcon />}
        onClick={handleExpired}
        >
          Eliminar
        </Button>
      </CardActions>
    </Card>
  );
}
