import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles((theme) => ({
    titulo: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "#000000",
    },
  }));


export default function Filtro({handleMes, handleYear}){
    const classes = useStyles();

    const d = new Date();
    var n = d.getMonth();
    var y = d.getFullYear();

    const years = [
        '2021',
        '2022',
        '2023',
        '2024',
        '2025',
      ];
      const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiempre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];
      return(
      <div>  
            <FormControl variant="outlined" style={{width: "15%"}}>
              <InputLabel id="">Año</InputLabel>
              <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                label="Año"
                //multiple
                //value={personName}
                defaultValue={y}
                onChange={handleYear}
                //input={<Input />}
                //MenuProps={MenuProps}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{width: "15%", marginLeft: "20px"}}>
              <InputLabel id="">Mes</InputLabel>
              <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                label="Mes"
                //multiple
                //value={personName}
                defaultValue={months[n]}
                onChange={handleMes}
                //input={<Input />}
                //MenuProps={MenuProps}
              >
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
      );
}