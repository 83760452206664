import React, {useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";

const useStyles = makeStyles((theme) => ({
    titulo: {
      fontWeight: "bold",
      fontSize: "20px",
      color: "#000000",
    },
  }));


export default function Filtro({handleMenu, handleMes, handleYear}){
    const classes = useStyles();

    const d = new Date();
    var n = d.getMonth();
    var y = d.getFullYear();

    const years = [
        '2021',
        '2022',
        '2023',
        '2024',
        '2025',
      ];
      const months = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];
      return(
      <div>    
      <FormControl variant="outlined" style={{ width: "15%" }}>
              <InputLabel htmlFor="outlined-age-native-simple">Familia:</InputLabel>
              <Select 
              defaultValue={1} 
              id="grouped-select"
              onChange={handleMenu}
              label="Familia"
              //label="Holi"
              >
                <ListSubheader className={classes.titulo}>
                  Cartonería
                </ListSubheader>
                <MenuItem value={1}>Cartoneria - Vigentes</MenuItem>
                <MenuItem value={2}>Cartoneria - No vigentes</MenuItem>
                <ListSubheader className={classes.titulo}>Tambos</ListSubheader>
                <MenuItem value={3}>Tambos - Vigentes</MenuItem>
                <MenuItem value={4}>Tambos - No vigentes</MenuItem>
                <ListSubheader className={classes.titulo}>
                  Cubetas
                </ListSubheader>
                <MenuItem value={5}>Cubetas - Vigentes</MenuItem>
                <MenuItem value={6}>Cubetas - No vigentes</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{width: "15%",marginLeft: "20px"}}>
              <InputLabel id="">Año</InputLabel>
              <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                defaultValue={y}
                label="Año"
                //multiple
                //value={personName}
                onChange={handleYear}
                //input={<Input />}
                //MenuProps={MenuProps}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{width: "15%",marginLeft: "20px"}}>
              <InputLabel id="">Mes</InputLabel>
              <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                defaultValue={months[n]}
                label="Mes"
                //multiple
                //value={personName}
                onChange={handleMes}
                //input={<Input />}
                //MenuProps={MenuProps}
              >
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
      );
}