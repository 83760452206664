import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from "@material-ui/icons/Visibility";
import StoreIcon from "@material-ui/icons/Store";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import CategoryIcon from '@material-ui/icons/Category';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 420,
    backgroundColor: "#e0e0e0",
  },
  content: {
    padding: 3,
    
  },
  item: {
    marginTop: 10,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function MecanicaCard({ mecanica }) {
  const classes = useStyles();
  const handleExpired = () => {
    let data = {mecanica: mecanica.id}
    Axios.put(process.env.REACT_APP_API.concat(`mecanica/setexpired`), data, {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}},
    )
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        if(error.response.status == 401){
          localStorage.clear();
          window.location.reload();
        };
      });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
         {mecanica.id}. {mecanica.nombre ? mecanica.nombre : mecanica.familia} {mecanica.is_current == 1 && ('NO VIGENTE')}
        </Typography>
        <Divider />
        <Chip
          icon={mecanica.is_current == 0 ? <CheckIcon /> :  <ClearIcon />}
          label={mecanica.is_current == 0 ? "Vigente" : "No Vigente"}
          clickable
          color="primary"
          variant="outlined"
          className={classes.chip}
        />
        <Chip
          icon={<CategoryIcon />}
          label={"Familia: " + mecanica.familia}
          clickable
          color="primary"
          variant="outlined"
          className={classes.chip}
        />
        <Chip
          icon={<EventAvailableIcon />}
          label={"Inicio de la promoción: " + mecanica.fecha_inicio}
          clickable
          color="primary"
          className={classes.chip}
        />
        <Chip
          icon={<EventBusyIcon />}
          label={"Fin de la promoción: " + mecanica.fecha_fin}
          clickable
          color="primary"
          variant="outlined"
          className={classes.chip}
        />
        <Chip
          icon={<AccountCircleIcon />}
          label={"Creado por: " + mecanica.username}
          clickable
          color="primary"
          className={classes.chip}
        />
        <Chip
          icon={<AccessTimeIcon />}
          label={"Fecha/Hora de creación: "+mecanica.created_at}
          clickable
          color="primary"
          variant="outlined"
          className={classes.chip}
        />
        <Chip
          icon={<StoreIcon />}
          label={"Vigente en: " + mecanica.Sucursales}
          clickable
          color="primary"
          className={classes.chip}
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Link to={`/Mecanicas/${mecanica.id}`}>
          <Button size="small" startIcon={<VisibilityIcon />} color="primary">
            Ver detalles
          </Button>
        </Link>
        <Button
          size="small"
          onClick={handleExpired}
          startIcon={<DeleteIcon />}
          color="secondary"
          disabled={mecanica.is_current == 1 ? (true) : (false)}
        >
          Eliminar
        </Button>
      </CardActions>
    </Card>
  );
}
