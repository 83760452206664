import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../../Components/Sidebar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { useParams } from "react-router";
import axios from "../../axios.js";
import * as ReactBootStrap from "react-bootstrap";
import Promo1 from "./Components/Promo1";
import Promo2 from "./Components/Promo2";
import Promo3 from "./Components/Promo3";
import Promo4 from "./Components/Promo4";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    width: "100%",
    height: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "95%",
    marginLeft: "2%",
    marginRight: "2%",
    marginTop: "2%",
    alignItems: "center",
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 300,
  },
  promo: {
    marginTop: "5%",
  },
}));


export default function CrearPromocion({fetchPromociones}) {
  const classes = useStyles();
  const [tipo, setTipo] = React.useState("");
  const [open, setOpen] = React.useState("false");
  const [promo1, setPromo1] = React.useState(false);
  const [promo2, setPromo2] = React.useState(false);
  //const [promociones, setPromociones] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams();
  const mecanica = id;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);  
      const request = await axios.get(fetchPromociones.concat(mecanica), {headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((response) => {
      var temp = response.data;
      if(temp.length > 0){
        if(temp[0].tipo_id == 1){
          setPromo1(true);
        }
        if(temp[0].tipo_id == 2){
          setPromo1(true);
        } 
        if(temp[0].tipo_id == 3){
          setPromo2(true);
        }
        if(temp[0].tipo_id == 4){
          setPromo2(true);
        }
        setLoading(false);
      }else{
        setLoading(false);
      }  
      }).catch((error) => {
        if(error.response.status == 401){
          localStorage.clear();
          window.location.reload();
        };
      });    
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    setTipo(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={classes.body}>
      <Sidebar select="Promociones" />
      <div className={classes.container}>
      {loading ? (
          <div className={classes.loading}>
            <ReactBootStrap.Spinner animation="border" variant="primary" />
          </div>):(
        <div>
          <Button className={classes.button} onClick={handleOpen}>
            Seleccione tipo de promoción
          </Button>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">Tipo de promoción</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={tipo}
              onChange={handleChange}
            >
              <MenuItem value={1} disabled={promo2}>X en Y</MenuItem>
              <MenuItem value={2} disabled={promo2}>De X a Y</MenuItem>
              <MenuItem value={3} disabled={promo1}>Precio especial</MenuItem>
              <MenuItem value={4} disabled={promo1}>Precio especial a partir de X</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
        <div className={classes.promo}>
        <a><RenderSwitch data={tipo} mecanica={mecanica}/></a>
        </div>
      </div>
    </div>
  );
}

function RenderSwitch (param) {
  switch(param.data) {
    case 1 :
      return <Promo1 mecanica={param.mecanica}/>;
    case 2 :
      return <Promo2 mecanica={param.mecanica}/>;
    case 3 :
      return <Promo3 mecanica={param.mecanica}/>;
    case 4:
      return <Promo4 mecanica={param.mecanica}/>;    
    default:
      return '';  
  }
}
