import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import UploadKilometros from "./UploadKilometros";
import Fab from "@material-ui/core/Fab";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import axios from "../../axios";
import * as ReactBootStrap from "react-bootstrap";
import { TablePagination } from "@material-ui/core";
import MecanicaEmpty from "../Promociones/Components/MecanicaEmpty";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginBottom: '-50px',
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(2),
    //top: theme.spacing(5),
  },

  cell: {
    backgroundColor: "#fff176",
  },

  cell1: {
    backgroundColor: "#ffee58",
  },
}));

export default function MainKilometros({ fetchUrl }) {
  const classes = useStyles();
  const [kilometros, setKilometros] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl,{headers: {'Authorization': 'Bearer '+localStorage.getItem('token').replace(/"/g,'')}})
      .then((response) => {
        setKilometros(response.data);
        setLoading(true);
      }).catch((e) => {
        if(e.response.status == 401){
          localStorage.clear();
          window.location.reload();
        }
      });
    }
    fetchData();
  }, [fetchUrl]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      {loading ? (kilometros.length > 0 ? (
        <div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Clave SAP</TableCell>
                  <TableCell align="left">Producto</TableCell>
                  <TableCell align="left">Km por cada $100</TableCell>
                  <TableCell className={classes.cell} align="left">
                    Km por cada $100 oferta
                  </TableCell>
                  <TableCell className={classes.cell1} align="left">
                    Fecha inicio
                  </TableCell>
                  <TableCell className={classes.cell1} align="left">
                    Fecha fin
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(kilometros).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((keyName, i) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {kilometros[keyName].SKU}
                    </TableCell>
                    <TableCell align="left">
                      {kilometros[keyName].descripcion}
                    </TableCell>
                    <TableCell align="left">{kilometros[keyName].km}</TableCell>
                    <TableCell className={classes.cell} align="left">
                      {kilometros[keyName].km_oferta}
                    </TableCell>
                    <TableCell className={classes.cell1} align="left">
                      {kilometros[keyName].fecha_inicio}
                    </TableCell>
                    <TableCell className={classes.cell1} align="left">
                      {kilometros[keyName].fecha_fin}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15]}
            component="div"
            count={kilometros.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      ):
      (<div style={{ marginLeft: "30%" }}>
                <MecanicaEmpty />
              </div>)
      ) : (
        <ReactBootStrap.Spinner animation="border" variant="primary" />
      )}

      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        component={Link}
        to="/UploadKilometros"
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
